var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      "
    + container.escapeExpression(((helper = (helper = helpers.heading || (depth0 != null ? depth0.heading : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heading","hash":{},"data":data}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"This page seems to be missing...",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      "
    + container.escapeExpression(((helper = (helper = helpers.subHeading || (depth0 != null ? depth0.subHeading : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subHeading","hash":{},"data":data}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"We couldn't find what you were looking for.",{"name":"trans","hash":{},"data":data}))
    + "\n      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You could try searching for something else you love!",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"category-item topic-item col-md-4 col-xs-6\">\n              <a class=\"on-navigate\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.browseURL : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + " style=\"background-color:"
    + alias4(((helper = (helper = helpers.background || (depth0 != null ? depth0.background : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"background","hash":{},"data":data}) : helper)))
    + ";color:"
    + alias4(((helper = (helper = helpers.color || (depth0 != null ? depth0.color : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color","hash":{},"data":data}) : helper)))
    + "\">\n                <span class=\"text\">"
    + alias4(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "</span>\n              </a>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.browseURL || (depth0 != null ? depth0.browseURL : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"browseURL","hash":{},"data":data}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    return "href=\""
    + container.escapeExpression((helpers.categoryNameToUrl || (depth0 && depth0.categoryNameToUrl) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),{"name":"categoryNameToUrl","hash":{},"data":data}))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"error-404 col-sm-10 col-sm-offset-1\">\n  <div class=\"header-wrapper\">\n    <h1 class=\"heading\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.heading : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </h1>\n    <h3 class=\"subheading\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subHeading : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "    </h3>\n    <div id=\"search-container\">\n      <form class=\"form-horizontal dropdown\" role=\"form\" name=\"search\" action=\"/search/\" action=\"GET\" autocomplete=\"off\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-search",21,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <input id=\"search-query\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search Stories & People",{"name":"trans","hash":{},"data":data}))
    + "\" name=\"q\" type=\"text\" role=\"search\" spellcheck=\"false\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search Stories & People",{"name":"trans","hash":{},"data":data}))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\">\n      </form>\n    </div>\n  </div>\n  <div class=\"body-wrapper\">\n    <div class=\"row\">\n        <div class=\"categories-grid col-md-12\">\n          <div class=\"title col-xs-12\"><h3>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse Topics",{"name":"trans","hash":{},"data":data}))
    + "</h3></div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.browseTopics : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <a class=\"more col-md-4 col-xs-12\" href=\"/home\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Discover more",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",16,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n        </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});